<template>

  <section id="transporte-dashboard">
    <div class="row">
      <div class="col-12 section-head my-3">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h3 class="section-head-title p-3 ps-0 mb-0">
              <i class="fa-solid fa-bus-simple color-secondary"></i>
              Conoce las cifras más importantes de <strong>Transporte</strong>
            </h3>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <div class="row">
              <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
                <font-awesome-icon 
                  icon="filter" 
                  class="color-secondary pe-2"
                />
                Filtrar
              </div>
              <div class="col-12 col-lg-9 col-xl-8">
                <div class="row" v-click-outside="handleCalendar">
                  <div class="col-6">
                    <div 
                      class="form-control input-calendar" 
                      @click="openCalendar('start-date')">
                      <span>{{ dateFormatymd(init_date) }}</span>
                      <div class="icon">
                        <font-awesome-icon icon="calendar"/>
                      </div>
                      <div
                        @click="deleteDate()"
                        v-show="init_date != 'Inicio'"
                        class="input-calendar-clean">
                        <font-awesome-icon icon="times"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div 
                      class="form-control input-calendar disabled" 
                      v-if="init_date == 'Inicio'">
                      <span>{{ end_date }}</span>
                      <div class="icon">
                        <font-awesome-icon icon="calendar"/>
                      </div>
                    </div>
                    <div
                      class="form-control input-calendar"
                      v-if="init_date != 'Inicio'"
                      @click="openCalendar('end-date')">
                      <span>{{ dateFormatymd(end_date) }}</span>
                      <div class="icon">
                        <font-awesome-icon icon="calendar"/>
                      </div>
                      <div
                        @click="deleteDate()"
                        v-show="end_date != 'Término'"
                        class="input-calendar-clean">
                        <font-awesome-icon icon="times"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 position-relative">
                    <div 
                      class="input-calendar-dropdown" 
                      :class="calendar_side" 
                      v-if="show_calendar">
                      <v-calendar
                        color="blue"
                        :max-date="max_date"
                        :min-date="min_date"
                        :attributes="attributes"
                        @dayclick="onDayClick"
                      />
                      <div 
                        class="border-arrow" 
                        :class="calendar_side">
                        <div class="arrow"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-5">
        <Planificaciones class="" :plan="planificaciones_t"/>
      </div>
      <div class="col-12 col-lg-7">
        <div class="row mb-3">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <EstadisticasPorcentaje class="" :dashboard="global_historica_t"/>
          </div>
          <div class="col-12 col-lg-6">
            <EstadisticasUsuarios :dashboard="transporte_historico_t"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <EstadisticasPorcentaje class="" :dashboard="global_activa_t"/>
          </div>
          <div class="col-12 col-lg-6">
            <EstadisticasUsuarios :dashboard="transporte_activo_t"/>
          </div>
        </div>
      </div>
    </div>
    <hr class="border border-gray">
    <div class="row">
      <div class="col-12 col-lg-5">
        <Planificaciones :plan="planificaciones_a"/>
      </div>
      <div class="col-12 col-lg-7">
        <div class="row mb-3">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <EstadisticasPorcentaje :dashboard="global_historica_a"/>
          </div>
          <div class="col-12 col-lg-6">
            <EstadisticasUsuarios :dashboard="transporte_historico_a"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <EstadisticasPorcentaje :dashboard="global_activa_a"/>
          </div>
          <div class="col-12 col-lg-6">
            <EstadisticasUsuarios :dashboard="transporte_activo_a"/>
          </div>
        </div>
      </div>
    </div>   
    <Spinner v-if="show_spinner"/>
  </section>

</template>

<script>
import Planificaciones from "./Planificaciones.vue";
import EstadisticasPorcentaje from "./EstadisticasPorcentaje.vue";
import EstadisticasUsuarios from "./EstadisticasUsuarios.vue";
import { mapActions, mapState } from "vuex";
import Spinner from "../Spinner.vue";

export default {
  components: {
    Planificaciones,
    EstadisticasPorcentaje,
    EstadisticasUsuarios,
    Spinner,
  },
  data() {
    return {
      show_spinner: false,
      show_calendar: false,
      calendar_side: "",
      max_date: "",
      min_date: "",
      init_date: "Inicio",
      end_date: "Término",
      img_calendar: require("@/assets/img/utilities/calendar.svg"),
      start_calendar: false, //inicio
      date_start: "",
      end_calendar: false, //termino
      date_end: "",

      // Ocupacion Terrestre
      global_historica_t: {
        ocupacion: "cargando",
        proximas_fechas: "cargando",
        name: "global histórica",
      },
      global_activa_t: {
        ocupacion: "cargando",
        proximas_fechas: "cargando",
        name: "planificación activa",
      },

      // Ocupación aérea
      global_historica_a: {
        ocupacion: "cargando",
        proximas_fechas: "cargando",
        name: "global histórica",
      },
      global_activa_a: {
        ocupacion: "cargando",
        proximas_fechas: "cargando",
        name: "planificación activa",
      },

      // Transporte histórico
      transporte_historico_t: {
        solicitudes: "cargando",
        porcentaje: "cargando",
        name: "Transporte histórico",
      },
      transporte_activo_t: {
        solicitudes: "cargando",
        porcentaje: "cargando",
        name: "Planificación activa",
      },

      // Transporte activo
      transporte_historico_a: {
        solicitudes: "cargando",
        porcentaje: "cargando",
        name: "Transporte histórico",
      },
      transporte_activo_a: {
        solicitudes: "cargando",
        porcentaje: "cargando",
        name: "Planificación activa",
      },

      // viajes realizados
      planificaciones_t: {
        viajes_realizados: "",
        name: "Terrestre",
        image: require("@/assets/img/transporte/bus.png"),
      },
      planificaciones_a: {
        viajes_realizados: "",
        name: "Aéreo",
        image: require("@/assets/img/transporte/avion.png"),
      },
    };
  },

  computed: {
    ...mapState("transporteModule", ["dashboard"]),
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
  },

  async created() {
    this.show_spinner = true;
    let data = {
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      tipo_rango: "0",
      fecha_inicio: "",
      fecha_fin: "",
    };
    await this.getDashboardTransporte(data);
    this.fillProps();
    this.show_spinner = false;
  },

  methods: {
    ...mapActions("transporteModule", ["getDashboardTransporte"]),

    // calendario
    openCalendar(side) {
      this.show_calendar = true;
      this.calendar_side = side;
      if (this.calendar_side == "start-date") {
        this.min_date = "";
        if (this.end_calendar == true) {
          this.max_date = this.date_end;
        }
      }
      if (this.calendar_side == "end-date") {
        this.max_date = "";
        if (this.start_calendar == true) {
          this.min_date = this.date_start;
        }
      }
    },

    // Eliminando fecha
    async deleteDate() {
      this.show_spinner = true;
      (this.calendar_side = ""), (this.min_date = "");
      this.max_date = "";
      this.init_date = "Inicio";
      this.date_start = "";
      (this.start_calendar = false), (this.end_calendar = false);
      this.date_end = "";
      this.end_date = "Término";
      this.show_calendar = false;
      this.filtro_periodo = false;
      let data = {
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        tipo_rango: "0",
        fecha_inicio: "",
        fecha_fin: "",
      };
      await this.getDashboardTransporte(data);
      this.fillProps();
      this.show_spinner = false;
    },

    // selección de dia
    async onDayClick(day) {
      this.show_spinner = true;
      this.show_calendar = false;
      if (this.calendar_side == "start-date") {
        this.start_calendar = true;
        this.date_start = day.id;
        this.init_date = day.id;
      }
      if (this.calendar_side == "end-date") {
        this.end_calendar = true;
        this.date_end = day.id;
        this.end_date = day.id;
      }
      if (this.date_end != "") {
        let data = {
          id_empresa: this.$ls.get("user").empresa[0].id_empresa,
          tipo_rango: "1",
          fecha_inicio: this.date_start,
          fecha_fin: this.date_end,
        };
        await this.getDashboardTransporte(data);
        this.fillProps();
      }
      this.show_spinner = false;
    },

    //  cerrar calendario
    handleCalendar() {
      this.show_calendar = false;
    },

    // llenando data de props
    fillProps() {
      // Ocupacion terrestre
      this.global_historica_t.ocupacion = isNaN(
        this.dashboard[0].ocupacion_historica
      )
        ? 0
        : this.ratio(this.dashboard[0].ocupacion_historica);
      this.global_historica_t.proximas_fechas = this.upcomingDates(
        this.dashboard[0].proximas_fechas
      );
      this.global_activa_t.ocupacion = isNaN(this.dashboard[0].ocupacion_activa)
        ? 0
        : this.ratio(this.dashboard[0].ocupacion_activa);
      this.global_activa_t.proximas_fechas = this.upcomingDates(
        this.dashboard[0].proximas_fechas
      );

      // Ocupacion aérea
      this.global_historica_a.ocupacion = isNaN(
        this.dashboard[1].ocupacion_historica
      )
        ? 0
        : this.ratio(this.dashboard[1].ocupacion_historica);
      this.global_historica_a.proximas_fechas = this.upcomingDates(
        this.dashboard[1].proximas_fechas
      );
      this.global_activa_a.ocupacion = isNaN(this.dashboard[1].ocupacion_activa)
        ? 0
        : this.ratio(this.dashboard[1].ocupacion_activa);
      this.global_activa_a.proximas_fechas = this.upcomingDates(
        this.dashboard[1].proximas_fechas
      );

      // Transporte histórico
      this.transporte_historico_t.solicitudes = isNaN(
        this.dashboard[0].solicitudes_historicas
      )
        ? 0
        : this.dashboard[0].solicitudes_historicas;
      this.transporte_historico_t.porcentaje = isNaN(
        this.dashboard[0].ocupacion_historica
      )
        ? 0
        : this.ratio(this.dashboard[0].ocupacion_historica);
      this.transporte_activo_t.solicitudes = isNaN(
        this.dashboard[0].solicitudes_activas
      )
        ? 0
        : this.dashboard[0].solicitudes_activas;
      this.transporte_activo_t.porcentaje = isNaN(
        this.dashboard[0].ocupacion_activa
      )
        ? 0
        : this.ratio(this.dashboard[0].ocupacion_activa);

      // Transporte histórico
      this.transporte_historico_a.solicitudes = isNaN(
        this.dashboard[1].solicitudes_historicas
      )
        ? 0
        : this.dashboard[1].solicitudes_historicas;
      this.transporte_historico_a.porcentaje = isNaN(
        this.dashboard[1].ocupacion_historica
      )
        ? 0
        : this.ratio(this.dashboard[1].ocupacion_historica);
      this.transporte_activo_a.solicitudes = isNaN(
        this.dashboard[1].solicitudes_activas
      )
        ? 0
        : this.dashboard[1].solicitudes_activas;
      this.transporte_activo_a.porcentaje = isNaN(
        this.dashboard[1].ocupacion_activa
      )
        ? 0
        : this.ratio(this.dashboard[1].ocupacion_activa);

      // Planificacion
      this.planificaciones_t.viajes_realizados =
        this.dashboard[0].viajes_realizados;
      this.planificaciones_a.viajes_realizados =
        this.dashboard[1].viajes_realizados;
    },
    ratio(porcentaje) {
      porcentaje = parseFloat(porcentaje);
      return porcentaje;
    },

    upcomingDates(fechas) {
      if (fechas.length != 0) {
        return fechas[0].fecha_viaje;
      } else {
        return "sin datos";
      }
    },
  },
};
</script>

<style></style>
