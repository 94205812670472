<template>

  <div class="card card-shadow card-bars border-round-10">
    <div class="card-body">
      <div class="card-bars-title">
        {{dashboard.name}}
      </div>
      <div class="card-bars-count my-2">
        <div class="card-bars-count-num pe-2">{{dashboard.solicitudes}}</div>
        <div class="card-bars-count-val"> Usuarios</div>
      </div>
      <div class="card-bars-bar">
        <div 
          class="card-bars-bar-back bg-blue-gray">
        </div>
        <div 
          class="card-bars-bar-front bg-blue"
          v-if="dashboard.name=='Planificación activa'"
          :style="`width: ${dashboard.porcentaje }%`">
        </div>
        <div 
          class="card-bars-bar-front bg-blue"
          v-if="dashboard.name=='Transporte histórico'"
          :style="`width: ${dashboard.porcentaje }%`">
        </div>
      </div>
    </div>
  </div>  

<!--   <div class="estadisticas-container">
    <h2 class="estadisticas-container__title">
      {{dashboard.name}}
    </h2>
    <p class="estadisticas-container__text">
      {{dashboard.solicitudes}} <span class="estadisticas-container__text--user">Usuarios</span>
    </p>
    <div class="estadisticas-container__barra">
      <div
        class="estadisticas-container__barra--activo" v-if="dashboard.name=='Planificación activa'"
        role="progressbar"
        :style="`width: ${dashboard.porcentaje}%`"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
      <div
        class="estadisticas-container__barra--historico" v-if="dashboard.name=='Transporte histórico'"
        role="progressbar"
        :style="`width: ${dashboard.porcentaje}%`"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div> -->

</template>

<script>
export default {
  props: {
    dashboard: {
      type:  Object,
    },
  },

};
</script>

<style></style>
