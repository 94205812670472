<template>

  <div class="card card-shadow card-bars border-round-10">
    <div class="card-body">
      <div class="card-bars-title">Ocupación {{dashboard.name}}</div>
      <div class="card-bars-count my-2">
        <div class="card-bars-count-num pe-2">{{ratio(dashboard.ocupacion)}}%</div>
        <div 
          v-if="dashboard.name=='planificación activa'"
          class="card-bars-count-val">
          Salida: {{fechasProximas()}}
        </div>
      </div>
      <div class="card-bars-bar">
        <div 
          class="card-bars-bar-back bg-blue-gray">
        </div>
        <div 
          class="card-bars-bar-front bg-blue"
          v-if="dashboard.name=='planificación activa'"
          :style="`width: ${dashboard.ocupacion }%`">
        </div>
        <div 
          class="card-bars-bar-front bg-blue"
          v-if="dashboard.name=='global histórica'"
          :style="`width: ${dashboard.ocupacion }%`">
        </div>
      </div>
    </div>
  </div>      

<!--   <div class="estadisticas-container">
    <h2 class="estadisticas-container__title">
      Ocupación {{dashboard.name}}
    </h2>
    <div class="estadisticas-container__porcentaje">
      <p class="estadisticas-container__text">{{ratio(dashboard.ocupacion)}}%</p>
      <p class="estadisticas-container__text--user" v-if="dashboard.name=='planificación activa'">Salida: {{fechasProximas()}}</p>
    </div>
    <div class="estadisticas-container__barra">
      <div
        class="estadisticas-container__barra--activo" v-if="dashboard.name=='planificación activa'"
        role="progressbar"
        :style="`width: ${dashboard.ocupacion}%`"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
      <div
        class="estadisticas-container__barra--historico" v-if="dashboard.name=='global histórica'"
        role="progressbar"
        :style="`width: ${dashboard.ocupacion }%`"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div> -->

</template>

<script>
export default {
  props: {
    dashboard: {
      type:  Object,
    },
  },
  data(){
    return {

    };
  },
  methods:{
    fechasProximas(){
      if(this.dashboard.proximas_fechas.length != 0) {
        return this.dashboard.proximas_fechas[0].fecha_viaje;
      }
    },
    ratio(porcentaje) {
      porcentaje = parseFloat(porcentaje);
      return porcentaje;
    },
  }
};
</script>

<style></style>
